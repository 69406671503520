import { isEmpty } from "lodash";

import { LEGACY_GAME_NUMBERING_SEASONS } from '@hisports/scoresheet/src/constants';

const LEADING_ZEROS = /^(0+).*/
const normalizeValue = value => !value ? '' : value;
const hasLeadingZeros = number => {
  const [ , leadingZeros ] = LEADING_ZEROS.exec(number) || []
  return leadingZeros != null;
}

export const GAME_NUMBER_REGEX = /^(.*?)(\d+)(\D*)$/
export const PATCHED_GAME_NUMBER_REGEX = /^(?:(?:[a-zA-Z0-9-])(?!--))*$/

export const parseSequence = sequence => `${sequence.prefix || ''}${sequence.start || ''}${sequence.suffix || ''}`;

export const comparePrefixSuffix = (sequence1, sequence2) => {
  const { prefix: prefix1, suffix: suffix1 } = sequence1;
  const { prefix: prefix2, suffix: suffix2 } = sequence2;

  return normalizeValue(prefix1) === normalizeValue(prefix2) && normalizeValue(suffix1) === normalizeValue(suffix2);
}

// if no seasonId, we assume it's legacy
export const isLegacyGameNumbering = seasonId => !seasonId || LEGACY_GAME_NUMBERING_SEASONS.includes(seasonId)

export const validateGameNumber = (gameNumber, seasonId) => {
  if (!gameNumber) return;

  if (typeof gameNumber === 'object') {
    gameNumber = parseSequence(gameNumber);
  }

  const isValidGameNumber = GAME_NUMBER_REGEX.test(gameNumber);

  if (isLegacyGameNumbering(seasonId) || !isValidGameNumber) {
    return isValidGameNumber
  }

  // added more validation when its not legacy
  if (gameNumber.length > 15) return

  return PATCHED_GAME_NUMBER_REGEX.test(gameNumber);
}

export const getGameNumberValidationError = (gameNumber, seasonId) => {
  const CONTAINS_SPECIAL_CHAR_REGEX = /[^a-zA-Z0-9-]/;
  const CONTAINS_DOUBLE_DASH_REGEX = /--/;
  const MISSING_NUMBER_REGEX = /^[^\d]+$/;

  if (!isLegacyGameNumbering(seasonId) && gameNumber.length > 15) return 'too_many_characters';

  if (!isLegacyGameNumbering(seasonId) && CONTAINS_DOUBLE_DASH_REGEX.test(gameNumber)) return 'cannot_contain_consecutive_dashes';

  if (!isLegacyGameNumbering(seasonId) && CONTAINS_SPECIAL_CHAR_REGEX.test(gameNumber)) return 'cannot_contain_special_characters';

  if (MISSING_NUMBER_REGEX.test(gameNumber)) return 'must_contain_a_number';
}

export const parseGameNumber = (gameNumber) => {
  if (gameNumber == null) return {};

  if (typeof gameNumber === 'object') {
    gameNumber = parseSequence(gameNumber);
  }

  const [ , prefix, start, suffix ] = GAME_NUMBER_REGEX.exec(gameNumber) || [];
  const padLength = start != null ? start.length : 0;
  const number = Number(start) || 0;
  return {
    prefix,
    number,
    suffix,
    hasLeadingZeros: hasLeadingZeros(start),
    padLength,
  }
}

export const isInSequence = (gameNumber, sequence) => {
  if (gameNumber == null || !sequence) return false;

  const { prefix, number, suffix } = parseGameNumber(gameNumber);
  const { prefix: startPrefix, number: startNumber, suffix: startSuffix } = parseGameNumber(sequence);

  if (prefix !== startPrefix || suffix !== startSuffix) return false;

  return number >= startNumber
}

export const getHighestNumber = (games = [], sequence) => {
  if (!sequence) return;
  return games.reduce((highest, game) => {
    if (!isInSequence(game.number, sequence)) return highest;

    const { number } = parseGameNumber(game.number)
    if (highest && highest > number) return highest;
    return number;
  }, null)
}

export const overlaps = (sequence, sequences) => {
  if (isEmpty(sequences)) return false;

  const { start, groupId, current } = sequence;
  const sequenceCurrent = current || Number(start);

  return sequences
    .filter(s => s.id !== sequence.id)
    .some(checkingSequence => {
      const { start: checkingStart, current: checkingCurrent, groupId: checkingGroupId } = checkingSequence;

      if (!comparePrefixSuffix(sequence, checkingSequence) || groupId == checkingGroupId) return false

      const min = Number(checkingStart);
      const max = checkingCurrent || Number(checkingStart);

      return sequenceCurrent >= min && sequenceCurrent <= max;
    });
}

export const getNextNumber = (sequence, seasonId, scheduleSequences = []) => {
  const { start, current } = sequence;

  const nextCurrent = current != null ? current + 1 : Number(start);

  // maximum number reached
  if (overlaps({ ...sequence, current: nextCurrent }, scheduleSequences)) return;

  // construct next number
  const { prefix, suffix, hasLeadingZeros, padLength } = parseGameNumber(sequence);
  const leadingZerosLength = padLength - nextCurrent.toString().length;
  const leadingZeros = hasLeadingZeros && padLength > 1 ? '0'.repeat(leadingZerosLength) : ''

  const newGameNumber = `${prefix}${leadingZeros}${nextCurrent}${suffix}`;

  if (!validateGameNumber(newGameNumber, seasonId)) return;

  return newGameNumber;
}
