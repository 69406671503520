import React, { Fragment } from 'react';
import { TabbedForm, TabbedFormTabs, FormTab, useTranslate } from 'react-admin';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import Toolbar from '../../common/ra/Toolbar';
import SwitchInput from '../../common/inputs/SwitchInput';
import { isAuthorized } from '../../common/Authorize';
import { UpdatedAtField } from '../../common/fields/UpdatedAtField';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTab-wrapper": {
      color: theme.palette.tertiary.main
    }
  },
  updatedAt: {
    color: theme.palette.text.secondary
  },
}));

const GeneralTab = props => {
  const translate = useTranslate();
  return <FormTab label="resources.branchOfficeSettings.labels.tabs.general" {...props}>
    <Typography variant="subtitle2">{translate('resources.branchOfficeSettings.labels.assignments')}</Typography>
    <SwitchInput
      source="certifiedOfficialChanges"
      label="resources.branchOfficeSettings.fields.certifiedOfficialChanges"
      helperText="resources.branchOfficeSettings.helpers.certifiedOfficialChanges"
      {...inputProps}
    />
  </FormTab>
}

const InternalTab = props => {
  const classes = useStyles();
  return <FormTab label="resources.branchOfficeSettings.labels.tabs.featureFlags" className={classes.tabs} {...props}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SwitchInput
          source="ff_suspensions"
          label="resources.branchOfficeSettings.fields.ff_suspensions"
          helperText="resources.branchOfficeSettings.helpers.ff_suspensions"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12}>
        <SwitchInput
          source="ff_incidents"
          label="resources.branchOfficeSettings.fields.ff_incidents"
          helperText="resources.branchOfficeSettings.helpers.ff_incidents"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12}>
        <UpdatedAtField source="updatedAt" variant="caption" includePrefix className={classes.updatedAt} />
      </Grid>
    </Grid>
  </FormTab>
}

export default ({ office, ...props }) => {
  const showInternalTab = isAuthorized(office, 'branchOfficeSettings', 'internalTab')

  return <TabbedForm toolbar={<Toolbar />} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} component={Fragment} {...props} syncWithLocation={false}>
    <GeneralTab />
    {showInternalTab && <InternalTab />}
  </TabbedForm>
}
