import React from "react";
import { SimpleForm, TextInput, useRecordContext, useTranslate } from "react-admin";
import { useFormState } from "react-final-form";
import { DialogContentText, Grid, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { validateGameNumber } from "@hisports/scheduler";
import { getGameNumberValidationError, parseSequence } from '@hisports/scheduler/src/sequences/sequences';

import { useHttpClient } from "../../http";

import { ScheduleInput } from "../schedules/ScheduleInput";
import { ScheduleGroupInput } from "../groups/GroupInput";

const useStyles = makeStyles(theme => ({
  groups: {
    paddingBottom: theme.spacing(2)
  },
  sequence: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
  }
}))

const validate = values => {
  const errors = {};

  if (!values.scheduleId) {
    errors.scheduleId = 'ra.validation.required';
  }

  if (!values.start) {
    errors.start = 'ra.validation.required';
  } else if (isNaN(values.start)) {
    errors.start = 'ra.validation.numericality';
  } else if (values.start < 0) {
    errors.start = 'ra.validation.positive';
  } else {
    const gameNumber = parseSequence(values);
    if (!validateGameNumber(gameNumber, values.scheduleId)) {
      const gameNumberError = getGameNumberValidationError(gameNumber, values.scheduleId);
      errors.start = `resources.schedulesequences.validations.${gameNumberError}`;
    }
  }

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const InnerForm = ({ disabled, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values } = useFormState();

  const sequenceNumber = parseSequence(values);

  return <Grid container spacing={1} fullWidth>
    {!props?.initialValues?.scheduleId && <Grid item xs={12}>
      <ScheduleInput source="scheduleId" disabled label="resources.schedulesequences.fields.scheduleId" {...inputProps} />
    </Grid>}
    <Grid item xs={12}>
      <ScheduleGroupInput source="groupId" disabled={disabled} showNone={translate('ra.message.no_group')} className={classes.groups} {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextInput source="prefix" disabled={disabled} placeholder="A" helperText="ra.message.optional" label="resources.schedulesequences.fields.prefix" {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextInput source="start" disabled={disabled} placeholder="100" helperText={false} label="resources.schedulesequences.fields.start" {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextInput source="suffix" disabled={disabled} placeholder="B" helperText="ra.message.optional" label="resources.schedulesequences.fields.suffix" {...inputProps} />
    </Grid>
    <Typography variant="subtitle2" className={classes.sequence} >
      {translate('resources.schedulesequences.messages.starting_sequence', { sequence: sequenceNumber })}
    </Typography>
  </Grid>
}

export const ScheduleSequenceForm = ({ prevalidate, ...props }) => {
  const translate = useTranslate()
  const sequence = useRecordContext()
  const { data: prevalidateData, loading } = useHttpClient(`/schedulesequences/${sequence?.id}/prevalidate`, null, !prevalidate && !!sequence?.id);

  const isSequenceUsed = prevalidate?.isSequenceUsed || prevalidateData?.isSequenceUsed;

  return <>
    <DialogContentText>
      {translate('resources.schedulesequences.alerts.sequence_info')}
    </DialogContentText>
    {isSequenceUsed && <>
      <Alert severity="warning">
        {translate('resources.schedulesequences.alerts.sequence_in_use')}
      </Alert>
      <br />
    </>}
    <SimpleForm validate={validate} {...props}>
      <InnerForm disabled={isSequenceUsed || loading} {...props} />
    </SimpleForm>
  </>
}
