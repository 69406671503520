// application
export const FF_VERSION_MISMATCH = 'version-mismatch';
export const FF_FORETHOUGHT = 'forethought';
// account
export const FF_SSO = 'single-sign-on'
// scheduling
export const FF_TOURNAMENTS = 'tournaments';
export const FF_CUPS = 'cups';
export const FF_BRACKETS = 'brackets';
export const FF_ENFORCE_SCHEDULE_OFFICE = 'enforce-schedule-office';
export const FF_DRAFT_LIMIT_BYES = 'draft-limit-byes';
export const FF_DRAFT_CONFLICTS = 'draft-conflicts';
export const FF_DRAFT_DEFAULT_ROUNDS = 'draft-default-rounds';
export const FF_DRAFT_DEFAULT_SLOTS = 'draft-default-slots';
export const FF_SEQUENCES = 'sequences';
export const FF_DRAFT_GAMES = 'draft-games';
export const FF_SCHEDULE_TEAMS_ORDER = 'schedule-teams-order';
export const FF_SCHEDULE_TEAMS_DISABLE_ADD_SYNCED = 'schedule-teams-disable-add-synced'
export const FF_CROSS_SCHEDULING = 'cross-scheduling';
export const FF_TEAM_SHORT_NAMES = 'team-short-names';
// officiating
export const FF_SCOREKEEPERS = 'scorekeepers';
export const FF_TIMEKEEPERS = 'timekeepers';
export const FF_OFFICIALS = 'officials';
export const FF_ADDITIONAL_LINESPERSONS = 'additional-linespersons'
export const FF_GRADES = 'grades';
export const FF_OFFICIAL_HIDE_ASSIGNERS = 'official-hide-assigners';
export const FF_DISABLE_ADD_QUALIFICATIONS = 'disable-add-qualifications'
// scorekeeping
export const FF_SCOREKEEPING_APP = 'scorekeeping-app';
export const FF_PERIODS = 'periods';
export const FF_PENALTY_SERVED_BY = 'penalty-served-by';
export const FF_PENALTY_START_END = 'penalty-start-end';
export const FF_PENALTY_FILL_START = 'penalty-fill-start';
export const FF_DOUBLE_FORFEIT = 'double-forfeit';
export const FF_PHOTOS = 'photos';
export const FF_PRINT_LINEUP = 'print-lineup';
export const FF_BATTERS = 'batters';
export const FF_PITCHERS = 'pitchers';
export const FF_GOALIES = 'goalies';
export const FF_REQUIRE_LINEUPS = 'require-lineups';
export const FF_REQUIRE_GAME_START = 'require-game-start';
export const FF_REQUIRE_TEAMS = 'require-teams';
export const FF_LINEUP_SIGNATURE = 'lineup-signature';
export const FF_APPROVAL_SIGNATURE = 'approval-signature';
export const FF_HIDE_INFRACTION_NAME = 'hide-infraction-name';
export const FF_SPORTSMANSHIP = 'sportsmanship';
export const FF_RESTRICT_LINEUP_ENTRY = 'restrict-lineup-entry'
// suspensions
export const FF_CARDS = 'cards';
export const FF_FAULTY = 'faulty';
// deprecations
export const FF_SCHEDULE_MENU = 'schedule-menu';
export const FF_CMS = 'cms';
export const FF_ACCOUNTS = 'accounts';
export const FF_LEGACY_DRAFTS_DISABLED = 'legacy-drafts-disabled';
